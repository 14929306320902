import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
function Quote({quoteText, quoteAuthor}) {
  return (
    <section className='bg-grey3 text-black-2 flex flex-col md:flex-row mb-24'>
      <div className='flex flex-col max-w-md lg:max-w-2xl 2xl:max-w-5xl justify-center ml-auto p-12 pr-8 lg:p-20 lg:pr-16 tracking-extra-wide text-2xl leading-10 lg:w-3/6 xl:4/6 '>
        <motion.blockquote  initial={{ opacity: 0, y: -25 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, delay: 0.15, y: {duration: 0.5} }} className='italic font-medium max-w-md lg:pl-4 ml-auto'>{quoteText}
        <motion.p  initial={{ opacity: 0, y: -150 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, delay: 0.25, y: {duration: 0.35} }} className='mt-6 lg:mt-12'>{quoteAuthor}</motion.p>

        </motion.blockquote>
      </div>
      <StaticImage
      src="../images/jamie-camera-quote.png"
      loading="lazy"
      breakpoints={ [750, 1080, 1366, 1920, 3280] }
      placeholder="blurred"
      height={450}
      width={700}
      quality={100}
      formats={['auto', 'webp', 'avif']}
      alt="Banner image"
      className="z-10"
      objectPosition={`40% 50%`}
    />
    </section>
  )
}

export default Quote