import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
import { useStaticQuery, graphql } from "gatsby"
import ButtonLink from './ButtonLink'

function About() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {sourceInstanceName: {eq: "files"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)
  console.log(data.allFile.edges)
  return (
    <section id="about" className='mt-12 md:mt-24 lg:mt-32 mb-12'>
      <motion.div   viewport={{ once: true }}
 initial={{ opacity: 0, y: -25 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, y: {duration: 1} }} className="container mx-auto px-8 flex justify-center flex-col items-center mb-10">
        <h2 className='uppercase text-black-2 tracking-extra-wide text-3xl lg:text-4xl font-extrabold mb-4 tk-futura-pt-bold'>About Jamie</h2>
        <p className='text-purple font-semibold text-lg lg:text-xl flex flex-col md:block text-center'>
          <span className='uppercase tracking-extra-wide'>Filmmaker</span>
          <span className='uppercase tracking-extra-wide my-4 md:mx-6 py-4 md:py-0 md:my-0 md:px-6 border-y-2 md:border-y-0 md:border-x-2'>Photographer</span>
          <span className='uppercase tracking-extra-wide'>Storyteller</span>
        </p>
        <p className='text-grey2 leading-7 text-xl font-medium tracking-wide max-w-4xl px-5 mb-0'>
          <strong>As a filmmaker,</strong> Jamie cares deeply about creativity, practicality, and confidence when it comes to telling someone's story. Making films, working alongside a team, and watching different areas of expertise come together for a common goal of a bigger project is a rewarding and beautiful process.
        </p>
      </motion.div>
    <div className="flex mb-10 justify-center">
    <StaticImage
        src="../images/jamie1.jpg"
        loading="lazy"
        breakpoints={ [750, 1080, 1366, 1920, 3280] }
        height={800}
        width={550}
        quality={80}
        formats={['auto', 'webp', 'avif']}
        alt="Banner image"
        className="z-10"
      />
      <StaticImage
        src="../images/jamie2.jpg"
        loading="lazy"
        breakpoints={ [750, 1080, 1366, 1920, 3280] }
        height={800}
        width={650}
        quality={80}
        formats={['auto', 'webp', 'avif']}
        alt="Banner image"
        className="z-10"
      />
      <StaticImage
      src="../images/jamie3.png"
      loading="lazy"
      breakpoints={ [750, 1080, 1366, 1920, 3280] }
      height={800}
      width={550}
      quality={80}
      formats={['auto', 'webp', 'avif']}
      alt="Banner image"
      className="z-10"
    />
    </div>
      <motion.div viewport={{ once: true }}
 initial={{ opacity: 0, y: -25 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, delay: 0.15, y: {duration: 0.5} }} className="container mx-auto px-8 flex justify-center flex-col items-center">
        <p className='text-grey2 leading-7 text-xl tracking-wide max-w-4xl px-6 mb-8'>Currently, Jamie is enrolled in the Algonquin Film and Media Program. She has grown signficantly in each role on set and cares deeply about her classes and peers. Jamie has held several positions in the media field, and has been practicing photography and videography as a freelancer.</p>
        <div className='flex flex-col gap-4'>
          {/* TODO get demo reel link */}
          <ButtonLink text="Watch Demo Reel" url="https://vimeo.com/720367193" buttonStyle='btn--purple' />
          <ButtonLink text="Download Resume" url={data.allFile.edges[0].node.publicURL} buttonStyle='btn--purple' />
          <ButtonLink text="Contact" fill={true} url="mailto:jamiebouwman2@gmail.com"  />

        </div>
      </motion.div>
    </section>
  )
}

export default About