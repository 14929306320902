import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DescriptionBoxes from '../components/DescriptionBoxes'
import About from '../components/About'
import Quote from '../components/Quote'
import Portfolio from '../components/Portfolio'

const quoteText = `The shortest distance between truth and a human being is a story.`;
const quoteAuthor = `- Anthony de Mello`;
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    
    <DescriptionBoxes />
    <About />
    <Quote quoteText={quoteText} quoteAuthor={quoteAuthor} />
    <Portfolio />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage
