import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import "react-image-lightbox/style.css";
import StillGallery from './StillGallery';

function PortfolioItem({ color, title, subtitle, images, imageData }) {

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  console.log(isOpen)

  if (title === 'Still Photos') {
    return (
      <>
        <div className='flex flex-col lg:flex-row mb-2  no-scrollbar w-full'>
          <motion.div
            initial={{ opacity: 0, y: -25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100, delay: 0.15, y: { duration: 0.5 } }}
            onClick={() => setIsOpen(true)}
            className={`${color} cursor-pointer tracking-extra-wide portfolio-item flex justify-center items-center flex-col px-20 xl:px-18 text-center`}>
            <h3 className='text-3xl xl:text-4xl tracking-extra-wide font-bold uppercase tk-futura-pt-bold'>{title}</h3>
            {subtitle && <p className='mt-3 lg:text-xl uppercase tracking-extra-wide mb-0'>{subtitle}</p>}
          </motion.div>
          <div className='flex flex-row w-full lg:w-auto overflow-x-scroll  no-scrollbar '>
            {/* 105*188 grid lg:grid-cols-${columns}	 w-full*/}
            <div className={`flex  h-max w-max  justify-center  `}>{images.map((image, i) => {
              const gatsbyImage = getImage(image.node)
              return <button role="button" className='cursor-pointer h-max w-max' key={`${image.alt}-${i}`}
                initial={{ opacity: 0, y: -25 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "spring", stiffness: 100, delay: (0.25 * i), y: { duration: 0.5 } }} onClick={() => { setPhotoIndex(i); setIsOpen(true) }} ><GatsbyImage image={gatsbyImage} alt={image.alt} /></button>
            }
            )}</div>
          </div>
        </div>
        <StillGallery isOpen={isOpen} setIsOpen={setIsOpen} photoIndex={photoIndex} setPhotoIndex={setPhotoIndex} imageData={imageData} />
      </>
    )
  } else {
    return (
      <div className='flex flex-col lg:flex-row mb-2 no-scrollbar  bg-black w-full'>
        <motion.div
          initial={{ opacity: 0, y: -25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 100, delay: 0.15, y: { duration: 0.5 } }}
          onClick={() => setIsOpen(true)}
          className={`${color} tracking-extra-wide portfolio-item flex justify-center items-center flex-col px-20 xl:px-18 text-center`}>
          <h3 className='text-3xl xl:text-4xl tracking-extra-wide font-bold uppercase tk-futura-pt-bold'>{title}</h3>
          {subtitle && <p className='mt-3 lg:text-xl uppercase tracking-extra-wide mb-0'>{subtitle}</p>}
        </motion.div>
        <motion.div className='flex flex-row w-auto lg:w-auto overflow-x-scroll  no-scrollbar bg-black-1' initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 100, delay: 0.15, y: { duration: 0.5 } }}>
          {/* 105*188 grid lg:grid-cols-${columns}	 w-full*/}
          <div className={`flex  h-max w-max  justify-center`}>{images.map((image, i) => {
            let videoLink = '';
            if (image.videoType === 'youtube') { videoLink = `https://www.youtube.com/watch?v=${image.videoId}` } else if (image.videoType === 'vimeo') { videoLink = `https://vimeo.com/${image.videoId}` }
            let imageLink = '';
            if (image.videoType === 'youtube') { imageLink = `https://img.youtube.com/vi/${image.videoId}/hqdefault.jpg` } else {
              imageLink = image.url
            }

            // const gatsbyImage = getImage(image.url)
            return <a href={videoLink} target='_blank' rel='noopener noreferrer' className='w-max block' key={`${image.alt}-${i}`} aria-label={image.alt}>
              <img src={imageLink} alt={image.alt} className='mb-0 portfolio-image' loading="lazy" /></a>
          }
          )}</div>
        </motion.div>
      </div>
    )
  }
}

export default PortfolioItem