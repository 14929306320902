import React from 'react'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function StillGallery({isOpen, setIsOpen, photoIndex, setPhotoIndex, imageData}) {

  return (
    <>
      {(isOpen) && (
      <Lightbox 
      
        mainSrc={imageData.edges[photoIndex]?.node.childImageSharp.fixed.src}
        nextSrc={imageData.edges[(photoIndex + 1) % imageData.edges.length]?.node.childImageSharp.fixed.src}
        prevSrc={imageData.edges[(photoIndex + imageData.edges.length - 1) % imageData.edges.length]?.node.childImageSharp.fixed.src}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={
          () => setPhotoIndex((photoIndex + imageData.edges.length - 1) % imageData.edges.length)
        }
        onMoveNextRequest={
          () => setPhotoIndex((photoIndex + 1) % imageData.edges.length)
        }
      />
      
      )}
    </>
  )
}

export default StillGallery