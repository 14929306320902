import React from 'react'
import {motion} from 'framer-motion'

function DescriptionBoxes() {
  return (
    <section className='flex flex-wrap md:flex-nowrap gap-1 mt-2'>

      <motion.div   viewport={{ once: true }} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, y: {duration: 0.5} }} className="flex flex-col justify-center items-center bg-yellow text-white py-8 px-2 text-center w-full md:w-2/6">
      <p className='uppercase tracking-wide text-xl xl:text-3xl mb-2 tk-futura-pt-bold'>Special Occasions</p>
      <p className='italic max-w-xs xl:max-wd-sm mb-0 text-sm xl:text-xl xl:font-medium'>Remember your special day with beautiful photos and video</p>
      </motion.div>
      <motion.div   viewport={{ once: true }} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, y: {duration: 0.5} }} className="flex flex-col justify-center items-center bg-grey text-white py-8 px-2 text-center w-full md:w-2/6">
      <p className='uppercase tracking-wide text-xl xl:text-3xl mb-2 tk-futura-pt-bold'>Connect With Donors</p>
      <p className='italic max-w-xs xl:max-w-sm mb-0 text-sm xl:text-xl xl:font-medium'>Move people to take action and make the world a better place</p>
      </motion.div>
      <motion.div   viewport={{ once: true }} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }}   transition={{ type: "spring", stiffness: 100, y: {duration: 0.5} }} className="flex flex-col justify-center items-center bg-purple text-white py-8 px-2 text-center w-full md:w-2/6">
      <p className='uppercase tracking-wide text-xl xl:text-3xl mb-2 tk-futura-pt-bold'>Promote Your Business</p>
      <p className='italic max-w-xs xl:max-w-sm mb-0 text-sm xl:text-xl xl:font-medium'>Use the power of virtual story telling to connect with and inspire more customers</p>
      </motion.div>
    </section>
  )
}

export default DescriptionBoxes