import React from 'react'
import PortfolioItem from './PortfolioItem'
import { useStaticQuery, graphql } from 'gatsby'



function Portfolio() {

  const imageData = useStaticQuery(graphql`
  query {
    portfolioThumbs: allFile(filter: {sourceInstanceName: {eq: "portfolio"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 375, height: 250, layout: CONSTRAINED)

            }
          }
        }
      }
    
    portfolioImages: allFile(filter: {sourceInstanceName: {eq: "portfolio"}}) {
    edges {
      node {
        childImageSharp {
          fixed(quality: 90) {
            src
          }
        }
      }
    }
  }
}
`)

const studentWork = [
  {url: '', alt: 'Still Photo 1', videoId: 'bE7CqsmraXE', videoType: 'youtube'},
  {url: '', alt: 'Still Photo 2', videoId: 'v4UUkifIVkw', videoType: 'youtube'},
  {url: 'https://i.vimeocdn.com/video/1416776864-15dac709751669a81f246f6a0d83ba15d39eca06044d86c98b56774dab3bdfaa-d_450x250', alt: 'Still Photo 23', videoId: '700978202', videoType: 'vimeo'},
  {url: 'https://i.vimeocdn.com/video/1376612274-f1fcbc132634604cc85b7378d41102ef08acf775b4068ad4627d4a47b4588f7e-d_450x250', alt: 'Still Photo 23', videoId: '679340551', videoType: 'vimeo'},
  {url: 'https://i.vimeocdn.com/video/1414418969-364d5fcd26b11d370855d3da3512dfa2bfff9383292fa2a9d66e019eacebcb97-d_450x250', alt: 'Still Photo 23', videoId: '699660174', videoType: 'vimeo'},
  {url: 'https://i.vimeocdn.com/video/1454759805-6736736cc9015d965d45f21b25b21b22acb4bb6158a6d4da0a14beee91b36d1a-d_450x250', alt: 'Still Photo 23', videoId: '722358898', videoType: 'vimeo'},
];

const shortFilms = [
  {url: '', alt: 'Welcome to Hamilton', videoId: 'OtK_amTS8FA', videoType: 'youtube'},
  {url: '', alt: 'Magnum Opus', videoId: 'eRA1JihJcx0', videoType: 'youtube'},
];

const notForProfit = [
  {url: '', alt: 'Still Photo 1', videoId: 'NOTJUvQNNWY', videoType: 'youtube'},
  {url: '', alt: 'Welcome to Hamilton', videoId: 'V8rYbUrqGKc', videoType: 'youtube'},

];

const specialOccasion = [
  {url: '', alt: 'Still Photo 1', videoId: '8btFiQ2zlJk', videoType: 'youtube'},
  {url: '', alt: 'Still Photo 2', videoId: 'zeCYtNIeOCk', videoType: 'youtube'},
  {url: '', alt: 'Still Photo 3', videoId: 'Vq3NHhbR43c', videoType: 'youtube'},

];


  return (
    <section className='flex flex-col' id="portfolio">
      <h2 className='text-black-2 mb-12 tracking-extra-wide uppercase font-bold text-4xl lg:text-5xl text-center tk-futura-pt-bold'>Portfolio</h2>
      <PortfolioItem color='bg-yellow text-white' title='Student Work' images={studentWork} columns="3" />
      <PortfolioItem color='bg-purple text-white' title='Not For Profit'  images={notForProfit} columns="3" />
      <PortfolioItem color='bg-red text-white' title='Short Films' subtitle='(Student Work)'  images={shortFilms} columns="3" />
      <PortfolioItem color='bg-grey text-white' title='Special Occasion'  images={specialOccasion} columns="3" />
      <PortfolioItem color='bg-black-2 text-white' title='Still Photos' images={imageData.portfolioThumbs.edges} imageData={imageData.portfolioImages} columns="4" />
      <div className='mx-auto flex flex-col gap-8 my-12' id="contact">
          <a href='mailto:jamiebouwman2@gmail.com' className='btn btn--fill btn--wider'>Contact <span className='capitalize'>Jamie about Video Productions</span></a>
      </div>
    </section>
  )
}

export default Portfolio